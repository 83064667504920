<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <div>
        <router-link to="/user/create">
          <b-button variant="primary">
            <span class="align-middle">Add New User</span>
          </b-button>
        </router-link>
      </div>
      <div>
        <router-link to="/user/deleted-users">
          <b-button
            variant="danger"
            size="md"
          >
            <span class="align-middle">Show deleted Users</span>
          </b-button>
        </router-link>
      </div>
    </div>

    <b-card title="Users">
      <b-row class="my-1">

        <b-col
          cols="6"
        >
          <b-form-group
            class="mb-0 align-items-center"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="perPageSelect"
            label="Per page"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          class="ml-auto"
        >
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0 align-items-center"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        :busy.sync="isBusy"
        :items="items"
        :filter="filter"
      >
        <template #table-busy>
          <div class="text-center text-success my-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(ID)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(name)="data">
          {{ data.value }}
        </template>
        <template #cell(username)="data">
          {{ data.value }}
        </template>
        <template #cell(role)="data">
          {{ data.value }}
        </template>
        <template #cell(user_postal_group)="data">
          {{ arrayToString(data.value) }}
        </template>
        <template #cell(actions)="data">
          <div class="d-flex">
            <router-link :to="'/user/update/' + data.item.id">
              <b-button
                variant="flat-warning"
                class="btn-icon"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </router-link>
            <b-button
              variant="flat-danger"
              class="btn-icon"
              @click="deleteUser(data.item)"
            >
              <feather-icon icon="DeleteIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="right"
        first-number
        last-number
        size="sm"
        class="my-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" />
        </template>
      </b-pagination>
    </b-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const appModule = createNamespacedHelpers('app')
const userModule = createNamespacedHelpers('user')

export default {
  data() {
    return {
      isBusy: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 5,
      pageOptions: [5, 10, 20, 50, 100],
      filter: null,
      fields: [
        { key: 'ID', label: 'ID' },
        { key: 'name', label: 'name' },
        { key: 'username', label: 'username' },
        { key: 'role', label: 'role' },
        { key: 'user_postal_group', label: 'postcode groups' },
        { key: 'actions', label: 'actions' },
      ],
      items: [],
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    ...appModule.mapMutations(['UPDATE_LOADER']),
    ...userModule.mapActions(['FETCH_USERS', 'DELETE_USER']),
    async getUsers() {
      try {
        this.isBusy = true
        this.items = await this.FETCH_USERS()
        this.totalRows = this.items.length
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
      }
    },
    arrayToString(array) {
      let string = ''
      array.forEach(element => {
        string += `${element.postal_code_group.group_name}, `
      })
      string = string.replace(/,\s*$/, '')
      return string
    },
    async deleteUser(item) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this user', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              this.UPDATE_LOADER(true)
              const resp = await this.DELETE_USER(item.id)
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'User deleted!',
                    icon: 'PlusIcon',
                    variant: 'success',
                    text: 'User has been deleted successfully!',
                  },
                })
                this.getUsers()
              }
              this.UPDATE_LOADER(false)
            } catch (error) {
              this.UPDATE_LOADER(false)
            }
          }
        })
    },
  },
}
</script>
